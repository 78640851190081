interface RITextProps {
  x: number;
  y: number;
  text: string;
}

const RIText = ({ x, y, text }: RITextProps) => {
  const words = text.split(' ');

  return (
    <text
      x={x}
      y={y}
      textAnchor='middle'
      alignmentBaseline='middle'
      fontSize='14'
      fontFamily='Circular Std Sans Serif Font'
      fontWeight={400}
      fill='white'
      pointerEvents='none'
      letterSpacing={1.5}
    >
      {words.map((word, i) => {
        return (
          <tspan key={word} x={x} y={y + i * 15 - 5}>
            {word.toUpperCase()}
          </tspan>
        );
      })}
    </text>
  );
};

export default RIText;
