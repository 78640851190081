import { GraphOptions } from "../utils/functions";
import { User } from "./User";
import { RawDateString } from "./utilTypes";

export enum UEventName {
  graphDownload = 'graphDownload',
  RIDownload = 'RIDownload',
  filter = 'filter'
}

export interface UEvent {
  eventName: UEventName;
  user: User;
  date: Date;
  graphSettings: Partial<GraphOptions>;
  dayString: RawDateString;
  weekString: RawDateString;
  platform: string;
  language: string;
}
