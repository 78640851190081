import { useEffect, useState } from 'react';
import UserActions from './UserActions';
import { getUsers } from '../../services/userService';
import { User } from '../../types/User';
import UserActionsByHour from './UserActionsByHour';
import GraphSettingsUsage from './GraphSettingsUsage';

const UEvents = () => {
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    getUsers().then((res) => {
      if (!('data' in res)) {
        throw new Error('failure to fetch users');
      }
      setUsers(res.data);
    });
  }, []);
  return (
    <div style={{ padding: 6 }}>
      <h1 style={{ padding: 6 }}>Analytics</h1>
      <div>number of users: {users.length}</div>
      <UserActions />
      <UserActionsByHour />
      <GraphSettingsUsage />
    </div>
  );
};

export default UEvents;
