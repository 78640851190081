import { useAuth } from '../../contexts/AuthContext';
import { useAppSelector } from '../../utils/hooks';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../store/userSlice';
// @ts-ignore
import logo from '../../icons/logo_white.png';

export default function Header() {
  const currentUser = useAppSelector(selectUser);

  const navigate = useNavigate();
  const { logout, setError } = useAuth();

  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
    } catch {
      setError('Failed to logout');
    }
  }

  return (
    <>
      <nav className='px- px-2 sm:px-4 py-2.5 bg-blue border text-gray-900 rounded border'>
        <div className='container mx-auto flex flex-wrap items-center justify-between'>
          <img style={{ height: 100 }} src={logo} alt='logo'></img>
          <div className='flex md:order-2'>
            {currentUser && (
              <div
                className='text-white text-lg font-semibold hover:opacity-70'
                style={{ cursor: 'pointer' }}
                onClick={handleLogout}
              >
                Log Out
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
