import { center, middleRadius, outerRadius } from '../../../constants';
import { Segments } from '../../../types/RipenessIndex';
import RIText from './RIText';
import TrafficLightBlock from './TrafficLightBlock';
interface SegmentContentsProps {
  startAngle: number;
  endAngle: number;
  hoveredSegment: string | null;
  segment: Segments;
  isOuter: boolean;
}

const SegmentContents = ({
  startAngle,
  endAngle,
  hoveredSegment,
  segment,
  isOuter,
}: SegmentContentsProps) => {
  const angle =
    startAngle < endAngle
      ? (startAngle + endAngle) / 2
      : ((startAngle + endAngle + 360) % 360) / 2;

  const textRadius = isOuter ? (outerRadius * 3) / 4 : (middleRadius * 2) / 3;
  const x = center + textRadius * Math.cos((Math.PI * angle) / 180);
  const y = center + textRadius * Math.sin((Math.PI * angle) / 180);

  return (
    <g>
      {hoveredSegment === segment ? (
        // show traffic colors UI on hover
        <TrafficLightBlock segment={segment} x={x} y={y} />
      ) : (
        <RIText x={x} y={y} text={segment} />
      )}
    </g>
  );
};

export default SegmentContents;
