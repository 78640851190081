
export enum Segments {
  mhs = 'MHS',
  wo = 'WO',
  confident = 'Confident in Winning',
  Willingness = 'Willingness to compromise',
  firmness = 'Firmness not to compromise',
  hurt = 'Hurt',
  impossibility = 'Impossibility of winning',
  ri = 'Ripeness Index'
}
