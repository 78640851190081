import { ApiServiceReturnType } from "../types/Api";
import { UEvent } from "../types/UEvent";
import { createHeader, baseURL } from "./utils";

export const fetchAllUEvents = async (): Promise<ApiServiceReturnType<UEvent[]>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/getAllUEvents`, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
}


export type SaveUEventToDBArgs = Omit<UEvent, 'user' | 'dayString' | 'weekString' | 'language' | 'platform'>

export const saveUEventToDB = async ({ event }: { event: SaveUEventToDBArgs }): Promise<ApiServiceReturnType<UEvent>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/saveUEvent`, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        event: {
          ...event,
          language: navigator.language,
          platform: navigator.platform,
        },
      })
    });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};
