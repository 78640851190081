import { Navigate } from 'react-router-dom';
import { useAppSelector } from './hooks';
import { selectUser } from '../store/userSlice';

const WithPrivateRoute = ({ children }) => {
  const currentUser = useAppSelector(selectUser);

  if (currentUser) {
    return children;
  }

  return <Navigate to='/login' />;
};

export default WithPrivateRoute;
