import { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Day } from '../../../types/Day';
import { GraphOptions } from '../../../utils/functions';
import { AnnotationPluginOptions } from 'chartjs-plugin-annotation';
import { convertDaysToChartJSData, convertEventsToChartLabels } from './utils';
import { DEvent } from '../../../types/DEvent';
// @ts-ignore
import download from '../../../icons/download.png';
import { UEventName } from '../../../types/UEvent';
import { saveUEventToDB } from '../../../services/analyticsService';
interface LineGraphProps {
  data: Day[];
  title?: string;
  subtitle?: string;
  width?: string;
  height: string;
  graphOptions?: GraphOptions;
  events?: DEvent[];
  allowDownload?: boolean;
}

const LineGraph = ({
  data,
  title,
  height,
  width,
  subtitle,
  graphOptions,
  events,
  allowDownload = false,
}: LineGraphProps) => {
  const chartRef = useRef<any>(null);

  const handleDownload = () => {
    const chart = chartRef.current;
    if (chart) {
      const canvas = chart.canvas;
      const ctx = canvas.getContext('2d');
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.restore();

      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `Didi Data.png`;
      link.click();
    }
    const UEvent = {
      date: new Date(),
      eventName: UEventName.graphDownload,
      graphSettings: graphOptions || {},
    };
    saveUEventToDB({ event: UEvent })
      .then()
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <div
      className='p-4'
      style={{
        backgroundColor: 'white',
        width: '100%',
        ...(width && { width }),
        ...(height && { height }),
      }}
    >
      {allowDownload && (
        <button
          onClick={handleDownload}
          style={{ border: 'none', position: 'absolute', right: 50 }}
        >
          <img src={download} alt='download'></img>
        </button>
      )}
      <Line
        ref={chartRef}
        options={{
          maintainAspectRatio: false,
          color: '#5C77B6',
          plugins: {
            subtitle: {
              text: subtitle,
              display: !!subtitle,
              align: 'start',
              color: 'navy',
            },
            title: {
              text: title || '',
              align: 'start',
              display: !!title,
              color: 'navy',
              font: {
                size: 30,
                weight: 'normal',
                family: 'Circular Std Sans Serif Font',
              },
            },
            legend: {
              labels: {
                boxHeight: 50,
                usePointStyle: true,
                pointStyle: 'circle',
              },
            },
            annotation: {
              annotations: convertEventsToChartLabels(
                events || [],
                graphOptions
              ) as unknown as AnnotationPluginOptions['annotations'],
            },
          },
        }}
        data={convertDaysToChartJSData(data, graphOptions)}
      />
    </div>
  );
};

export default LineGraph;
