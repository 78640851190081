import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { fetchUser } from '../services/userService';
import auth from '../config/firebase';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { setUser, resetUser, selectUserLoading } from '../store/userSlice';
import { setLoading } from '../store/appSlice';

interface IAuthContext {
  login: (email: string, password: string) => void;
  register: (email: string, password: string) => void;
  logout: () => void;
  setError: (error: string) => void;
  error: string;
}

const AuthContext = createContext<IAuthContext>({
  login: () => {},
  register: () => {},
  logout: () => {},
  setError: () => {},
  error: '',
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: PropsWithChildren) {
  const [error, setError] = useState('');
  const loading = useAppSelector(selectUserLoading);
  const dispatch = useAppDispatch();

  async function register(email: string, password: string) {
    try {
      dispatch(setLoading(true));
      await createUserWithEmailAndPassword(auth, email, password);
      fetchUser()
        .then((res) => {
          if (res instanceof Error) {
            throw new Error(res.message);
          }
          dispatch(setUser(res.data));
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
    dispatch(setLoading(false));
  }

  async function login(email: string, password: string) {
    dispatch(setLoading(true));
    await signInWithEmailAndPassword(auth, email, password);
    fetchUser()
      .then((res) => {
        if (res instanceof Error) {
          throw new Error(res.message);
        }
        dispatch(setUser(res.data));
      })
      .catch((e) => {
        console.log(e);
      });
    dispatch(setLoading(false));
  }

  async function logout() {
    await signOut(auth);
    dispatch(resetUser());
  }

  useEffect(() => {
    dispatch(setLoading(true));
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUser()
          .then((res) => {
            if (res instanceof Error) {
              dispatch(setLoading(false));
              throw new Error(res.message);
            }
            dispatch(setUser(res.data));
            dispatch(setLoading(false));
          })
          .catch((e) => {
            dispatch(setLoading(false));
            console.log(e);
          });
      }
    });
    dispatch(setLoading(false));

    return unsubscribe;
  }, [dispatch]);

  const value = {
    error,
    setError,
    login,
    register,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
