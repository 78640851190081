import { deleteEvent } from '../../store/dEventsSlice';
import { DEvent } from '../../types/DEvent';
import { useAppDispatch } from '../../utils/hooks';

const EventItem = ({ event }: { event: DEvent }) => {
  const dispatch = useAppDispatch();

  const onClickDelete = () => {
    dispatch(deleteEvent({ eventId: event._id }));
  };
  return (
    <div
      style={{
        padding: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <h4>Title: {event.title}</h4>
      <h5>Description: {event.description}</h5>
      <h5>ORGS: {event.associatedOrganisations.join(', ')}</h5>
      <h5>Date: {event.date.toString()}</h5>
      {<div>Tags: {event.tags.length ? event.tags.join(', ') : 'none'}</div>}
      <button onClick={onClickDelete}>delete</button>
    </div>
  );
};

export default EventItem;
