import {
  resetModal,
  selectIsModalVisible,
  selectModalText,
  setModalText,
} from '../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

export interface ModalProps {
  type?: 'info' | 'error';
}
const Modal = ({ type = 'info' }: ModalProps) => {
  const isModalVisible = useAppSelector(selectIsModalVisible);
  const modalText = useAppSelector(selectModalText);
  const dispatch = useAppDispatch();
  const onClickOk = () => {
    dispatch(resetModal());
    dispatch(setModalText(''));
  };

  return (
    <>
      {isModalVisible && modalText && (
        <div className='overlay'>
          <div
            style={{
              borderColor: type === 'info' ? 'blue' : 'red',
              borderWidth: 4,
              borderStyle: 'solid',
              width: '50%',
              height: '50%',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              padding: 10,
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <div style={{ margin: 24, textAlign: 'center' }}>{modalText}</div>
            <div style={{ display: 'flex', gap: 10 }}>
              <button onClick={onClickOk}>OK</button>;
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
