import { ApiServiceReturnType } from "../types/Api";
import { DEvent, DEventDTO } from "../types/DEvent";
import { createHeader, baseURL } from "./utils";

export const fetchAllOrgs = async (): Promise<ApiServiceReturnType<string[]>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/getOrganizations`, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};

export const fetchDEvents = async (): Promise<ApiServiceReturnType<DEvent[]>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/getOrgEvents`, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};

export const saveDEventsToDB = async ({ events }: { events: DEventDTO[] }): Promise<ApiServiceReturnType<DEvent[]>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/saveDEvents`, {
      headers,
      method: 'POST',
      body: JSON.stringify(events)
    });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};

export const deleteEventFromDB = async ({ eventId }: { eventId: string }): Promise<ApiServiceReturnType<string>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/deleteEvent`, {
      headers,
      method: 'DELETE',
      body: JSON.stringify({ eventId })
    });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};
