import { useRef, useState } from 'react';
import SegmentContents from './MiddleSegmentContent';
import {
  center,
  explainerText,
  innerRadius,
  leftSideMHSSegments,
  middleRadius,
  outerRadius,
  rightSideWOSegments,
  RIDiagramWidth,
} from '../../../constants';
import { Segments } from '../../../types/RipenessIndex';
import { getWindowDimensions, useAppSelector } from '../../../utils/hooks';
import { selectRipenessIndices } from '../../../store/dataSlice';
import { getColorFromRI } from '../../../utils/functions';
import TrafficLightBlock from './TrafficLightBlock';
import RIText from './RIText';
// @ts-ignore
import download from '../../../icons/download.png';
import { saveUEventToDB } from '../../../services/analyticsService';
import { UEventName } from '../../../types/UEvent';

const RipenessIndex = () => {
  const RIs = useAppSelector(selectRipenessIndices);
  const [hoveredSegment, setHoveredSegment] = useState<Segments | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const windowWidth = getWindowDimensions().width;
  const sideSpace = (windowWidth - RIDiagramWidth) / 2;

  const downloadSvg = () => {
    if (svgRef.current) {
      const svg = svgRef.current;
      const serializer = new XMLSerializer();
      const source = serializer.serializeToString(svg);

      const xmlSource = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      const svgBlob = new Blob([xmlSource], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const url = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `Didi Ripeness Index ${new Date().toDateString()}.svg`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
    }

    saveUEventToDB({
      event: {
        date: new Date(),
        eventName: UEventName.RIDownload,
        graphSettings: {},
      },
    })
      .then()
      .catch((e) => console.log(e));
  };
  const createArcPath = (
    startAngle: number,
    endAngle: number,
    radius: number
  ) => {
    const startX = center + radius * Math.cos((Math.PI * startAngle) / 180);
    const startY = center + radius * Math.sin((Math.PI * startAngle) / 180);
    const endX = center + radius * Math.cos((Math.PI * endAngle) / 180);
    const endY = center + radius * Math.sin((Math.PI * endAngle) / 180);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return `M ${center},${center} L ${startX},${startY} A ${radius},${radius} 0 ${largeArcFlag},1 ${endX},${endY} Z`;
  };

  return (
    <>
      <button
        style={{ border: 'none', position: 'absolute', right: 50 }}
        onClick={downloadSvg}
      >
        <img src={download} alt='download'></img>
      </button>
      <svg ref={svgRef} width={RIDiagramWidth} height={RIDiagramWidth}>
        {/* Outer Circle - Left Side MHS Segments */}
        {leftSideMHSSegments.map((segment, i, arr) => {
          const startAngle = 90 + (i * 180) / arr.length;
          const endAngle = 90 + ((i + 1) * 180) / arr.length;
          return (
            <g
              key={`left-${i}`}
              onMouseEnter={() => setHoveredSegment(segment)}
              onMouseLeave={() => setHoveredSegment(null)}
            >
              <path
                d={createArcPath(startAngle, endAngle, outerRadius)}
                fill={getColorFromRI(RIs[segment].total)}
                stroke='white'
                strokeWidth='2'
              />
              <SegmentContents
                startAngle={startAngle}
                endAngle={endAngle}
                hoveredSegment={hoveredSegment}
                segment={segment}
                isOuter={true}
              />
            </g>
          );
        })}

        {/* Outer Circle - Right Side WO Segments */}
        {rightSideWOSegments.map((segment, i, arr) => {
          const startAngle = 270 + (i * 180) / arr.length;
          const endAngle = 270 + ((i + 1) * 180) / arr.length;
          return (
            <g
              key={`right-${i}`}
              onMouseEnter={() => setHoveredSegment(segment)}
              onMouseLeave={() => setHoveredSegment(null)}
            >
              <path
                d={createArcPath(startAngle, endAngle, outerRadius)}
                fill={getColorFromRI(RIs[segment].total)}
                stroke='white'
                strokeWidth='2'
              />
              <SegmentContents
                startAngle={startAngle}
                endAngle={endAngle}
                hoveredSegment={hoveredSegment}
                segment={segment}
                isOuter={true}
              />
            </g>
          );
        })}

        {/* Middle Circle Divided into 2  */}
        <g
          onMouseEnter={() => setHoveredSegment(Segments.mhs)}
          onMouseLeave={() => setHoveredSegment(null)}
        >
          <path
            d={createArcPath(90, 270, middleRadius)}
            fill={getColorFromRI(RIs[Segments.mhs].total)}
            stroke='white'
            strokeWidth='2'
          />
          <SegmentContents
            startAngle={90}
            endAngle={270}
            segment={Segments.mhs}
            hoveredSegment={hoveredSegment}
            isOuter={false}
          />
        </g>
        <g
          onMouseEnter={() => setHoveredSegment(Segments.wo)}
          onMouseLeave={() => setHoveredSegment(null)}
        >
          <path
            d={createArcPath(270, 90, middleRadius)}
            fill={getColorFromRI(RIs[Segments.wo].total)}
            stroke='white'
            strokeWidth='2'
          />
          <SegmentContents
            startAngle={270}
            endAngle={90}
            segment={Segments.wo}
            hoveredSegment={hoveredSegment}
            isOuter={false}
          />
        </g>

        {/* Inner Circle */}
        <circle
          cx={center}
          cy={center}
          r={innerRadius}
          stroke='white'
          strokeWidth='2'
        />
        <circle
          cx={center}
          cy={center}
          r={innerRadius}
          fill={getColorFromRI(RIs[Segments.ri].total)}
          onMouseEnter={() => setHoveredSegment(Segments.ri)}
          onMouseLeave={() => setHoveredSegment(null)}
        />
        {hoveredSegment === Segments.ri ? (
          <TrafficLightBlock segment={Segments.ri} x={center} y={center} />
        ) : (
          <RIText x={center} y={center} text='Ripeness Index' />
        )}
      </svg>
      <div
        style={{
          border: 'none',
          position: 'absolute',
          left: RIDiagramWidth + sideSpace,
          bottom: -80,
          paddingRight: 30,
        }}
      >
        {hoveredSegment
          ? explainerText[hoveredSegment]
          : 'Further Information available on hover'}
      </div>
    </>
  );
};

export default RipenessIndex;
