import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { loadOrgs, selectEvents } from '../../store/dEventsSlice';
import EventItem from './EventItem';
import NewEventForm from './NewEventForm';

const Events = () => {
  const dispatch = useAppDispatch();
  const events = useAppSelector(selectEvents);
  useEffect(() => {
    dispatch(loadOrgs());
  }, [dispatch]);

  return (
    <div style={{ margin: 12 }}>
      <h1>Events</h1>
      {events.map((event) => {
        return (
          <div
            key={event._id}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px',
              margin: '10px',
            }}
          >
            <EventItem event={event} />
          </div>
        );
      })}
      <NewEventForm />
    </div>
  );
};

export default Events;
