import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useAppSelector } from '../../utils/hooks';
import { selectUEvents } from '../../store/uEventsSlice';
import { UEventName } from '../../types/UEvent';

const GraphSettingsUsage: React.FC = () => {
  const [chartData, setChartData] = useState<any>(null);
  const userEvents = useAppSelector(selectUEvents);

  useEffect(() => {
    const langCount: { HE: number; AR: number } = { HE: 0, AR: 0 };
    const parentCategoryCount: { [category: string]: number } = {};

    const filteredUserEvents = userEvents.filter(
      (u) => u.eventName === UEventName.filter
    );

    filteredUserEvents.forEach((event) => {
      const { graphSettings } = event;
      if (graphSettings) {
        // Count language selections (HE or AR)
        if (graphSettings.lang) {
          graphSettings.lang.forEach((lang) => {
            langCount[lang]++;
          });
        }

        // Count parent category selections
        if (graphSettings.parentCategories) {
          graphSettings.parentCategories.forEach((category) => {
            if (!parentCategoryCount[category]) {
              parentCategoryCount[category] = 0;
            }
            parentCategoryCount[category]++;
          });
        }
      }
    });

    // Preparing labels and datasets for the bar chart
    const langLabels = ['HE', 'AR'];
    const langData = [langCount.HE, langCount.AR];

    const parentCategoryLabels = Object.keys(parentCategoryCount);
    const parentCategoryData = parentCategoryLabels.map(
      (category) => parentCategoryCount[category]
    );

    setChartData({
      labels: [...langLabels, ...parentCategoryLabels],
      datasets: [
        {
          label: 'Parameter Usage',
          data: [...langData, ...parentCategoryData].map(
            (n) => n / filteredUserEvents.length
          ),
          backgroundColor: 'rgba(153, 102, 255, 0.5)',
          barPercentage: 0.5,
        },
      ],
    });
  }, [userEvents]);

  if (!chartData) {
    return <div>Loading chart...</div>;
  }

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Graph Settings Usage in Filtering Data',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Graph Settings',
              },
            },
            y: {
              title: {
                display: true,
                text: '% chance of being included in filter',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default GraphSettingsUsage;
