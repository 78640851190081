import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import WithPrivateRoute from './utils/WithPrivateRoute';
import Home from './components/layouts/Home';
import Header from './components/layouts/Header';
import ErrorMessage from './components/layouts/ErrorMessage';
import { Provider } from 'react-redux';
import { store } from './store';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import { useRef } from 'react';
import Loader from './components/layouts/Loader';
import Modal from './components/layouts/Modal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ...registerables,
  Annotation
);

function App() {
  const windowWidth = useRef(window.innerWidth);

  return windowWidth.current < 800 ? (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      Our Data Dashboard is not currently available on mobile! Please view on a
      wider screen. <div>- The Didi Team</div>
    </div>
  ) : (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Loader>
            <Modal />
            <Header />
            <ErrorMessage />
            <Routes>
              <Route path='/register' element={<Register />} />
              <Route path='/login' element={<Login />} />
              <Route
                path='/'
                element={
                  <WithPrivateRoute>
                    <Home />
                  </WithPrivateRoute>
                }
              />
            </Routes>
          </Loader>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
