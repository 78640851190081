import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSliceReducer from './userSlice';
import appSliceReducer from './appSlice';
import dataSlice from './dataSlice';
import dEventsSlice from './dEventsSlice';
import uEventsSlice from './uEventsSlice';

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    appState: appSliceReducer,
    data: dataSlice,
    dEvents: dEventsSlice,
    uEvents: uEventsSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
