import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { User, } from "../types/User";
import { SuccessApiResponse } from "../types/Api";
import { fetchUser, saveUserToDB } from "../services/userService";
import { setLoading } from "./appSlice";

interface UserState {
  user: User | null;
  loading: boolean;
}

export const initialState: UserState = {
  loading: false,
  user: null
};

export const loadUser = createAsyncThunk<
  SuccessApiResponse<User>
>("user/fetchUser", async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await fetchUser();
    if (!('data' in response)) {
      throw new Error(response.message)
    }
    if (response.data) {
      return response;
    } else {
      throw new Error('User not found');
    }
  } finally {
    dispatch(setLoading(false));
  }
});

export const registerUser = createAsyncThunk<
  SuccessApiResponse<User>,
  { organization: string },
  { state: RootState }
>("user/registerUser", async (args) => {
  const response = await saveUserToDB(args);
  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('User not found');
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = null;
    },
    setIsUserLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        state.user = action.payload.data;
      })
  },
});

export const { setUser, resetUser } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectUserLoading = (state: RootState) => state.user.loading;

export default userSlice.reducer;
