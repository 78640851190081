import { useState, FormEvent } from 'react';
import {
  setIsModalVisible,
  setLoading,
  setModalText,
} from '../../store/appSlice';
import { selectOrgs, saveDEvents } from '../../store/dEventsSlice';
import { DEventDTO } from '../../types/DEvent';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { RawDateString } from '../../types/utilTypes';

const today = new Date().toISOString().split('T')[0] as RawDateString;
const NewEventForm = () => {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<RawDateString>(today);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>(
    []
  );
  const [tags, setTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState('');

  const orgs = useAppSelector(selectOrgs);
  const onSelectChange = (
    selectedOptions: HTMLSelectElement['selectedOptions']
  ) => {
    const selectedValues = Array.from(selectedOptions).map(
      (option) => option.value
    );
    setSelectedOrganisations(selectedValues);
  };

  const addTag = () => {
    if (tagInput.trim() !== '' && !tags.includes(tagInput.trim())) {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const removeTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const onFormSubmit = (e: FormEvent) => {
    dispatch(setLoading(true));
    e.preventDefault();
    const newEvent: DEventDTO = {
      associatedOrganisations: selectedOrganisations,
      title,
      description,
      tags,
      dayString: date,
    };
    dispatch(saveDEvents({ events: [newEvent] }))
      .unwrap()
      .then(() => {
        setDate(today);
        setTitle('');
        setDescription('');
        setTags([]);
        setTagInput('');
        setSelectedOrganisations([]);
      })
      .catch((e) => {
        dispatch(setModalText('error saving event, please try again later'));
        dispatch(setIsModalVisible(true));
      });
  };

  return (
    <>
      <h1>New Events</h1>
      <form
        onSubmit={onFormSubmit}
        style={{
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <div className='event-input'>
          <label htmlFor='title'>Title</label>
          <input
            required
            name='title'
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className='event-input'>
          <label htmlFor='desc'>Description</label>
          <input
            value={description}
            name='desc'
            required
            type='text'
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className='event-input'>
          <label htmlFor='date'>Date (MM/DD/YYYY)</label>
          <input
            required
            name='date'
            type='date'
            value={date}
            onChange={(e) => setDate(e.target.value as RawDateString)}
          />
        </div>
        <div className='event-input'>
          <label htmlFor='organization'>Organizations</label>
          <select
            required
            style={{ width: 300 }}
            name='organization'
            multiple
            value={selectedOrganisations}
            onChange={(e) => onSelectChange(e.target.selectedOptions)}
          >
            {!!orgs.length &&
              orgs.map((o) => {
                return (
                  <option key={o} value={o}>
                    {o}
                  </option>
                );
              })}
          </select>
        </div>
        <div className='event-input'>
          <label htmlFor='tags'>Tags</label>
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            {tags.map((tag) => (
              <div
                key={tag}
                style={{ display: 'flex', alignItems: 'center', margin: '5px' }}
              >
                <span style={{ marginRight: '5px' }}>{tag}</span>
                <button type='button' onClick={() => removeTag(tag)}>
                  x
                </button>
              </div>
            ))}
          </div>
          <input
            name='tags'
            type='text'
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addTag();
              }
            }}
          />
          <button type='button' onClick={addTag}>
            Add Tag
          </button>
        </div>
        <button
          style={{
            width: 100,
            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: 1,
          }}
          type='submit'
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default NewEventForm;
