import { useState, useEffect, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useAppSelector } from '../../utils/hooks';
import { selectUser } from '../../store/userSlice';

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const currentUser = useAppSelector(selectUser);

  const { login, setError } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(email, password);
      navigate('/');
    } catch (e: any) {
      console.log(e.message);
      setError('Failed to login');
    }

    setLoading(false);
  }

  return (
    <div
      style={{ minHeight: 'calc(100vh - 121px)' }}
      className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-[#FFB81C] to-[#E06287]'
    >
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='mt-4 text-3xl text-center tracking-tight font-light '>
            Login to your account
          </h2>
        </div>
        <form className='mt-8 space-y-6' onSubmit={handleFormSubmit}>
          <div className='rounded-md shadow-sm -space-y-px'>
            <div className='py-2'>
              <input
                id='email-address'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500  focus:z-10 sm:text-sm'
                placeholder='Email address'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='py-2'>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                className='appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500       focus:z-10 sm:text-sm'
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type='submit'
              disabled={loading}
              className=' w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-800 hover:bg-sky-900'
            >
              Login
            </button>
          </div>
          <div className='flex items-center justify-between'>
            <div className='text-sm'>
              <Link to='/register' className='text-blue-600 hover:underline '>
                Don't have an account? Register
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
