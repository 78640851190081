import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useAppSelector } from '../../utils/hooks';
import { selectUEvents } from '../../store/uEventsSlice';
import dayjs from 'dayjs';

const UserActionsByHour: React.FC = () => {
  const [chartData, setChartData] = useState<any>(null);
  const userEvents = useAppSelector(selectUEvents);

  useEffect(() => {
    // Create a time range from 7 AM to 9 PM
    const hoursRange = Array.from({ length: 15 }, (_, i) => 7 + i);

    // Initialize an object to store the sum of actions for each hour
    const hourlyData: {
      [hour: string]: {
        graphDownload: number;
        RIDownload: number;
        filter: number;
      };
    } = {};

    hoursRange.forEach((hour) => {
      hourlyData[hour] = { graphDownload: 0, RIDownload: 0, filter: 0 };
    });

    // Aggregating the actions based on the event hour
    userEvents.forEach((event) => {
      const eventHour = dayjs(event.date).hour();
      if (eventHour >= 7 && eventHour <= 21) {
        hourlyData[eventHour][event.eventName]++;
      }
    });

    // Preparing the data for the chart
    const labels = hoursRange.map((hour) => `${hour}:00 - ${hour + 1}:00`);
    const graphDownloadData = hoursRange.map(
      (hour) => hourlyData[hour].graphDownload
    );
    const RIDownloadData = hoursRange.map(
      (hour) => hourlyData[hour].RIDownload
    );
    const filterData = hoursRange.map((hour) => hourlyData[hour].filter);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Graph Download',
          data: graphDownloadData,
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
        },
        {
          label: 'RI Download',
          data: RIDownloadData,
          backgroundColor: 'rgba(153, 102, 255, 0.5)',
        },
        {
          label: 'Filter',
          data: filterData,
          backgroundColor: 'rgba(255, 159, 64, 0.5)',
        },
      ],
    });
  }, [userEvents]);

  if (!chartData) {
    return <div>Loading chart...</div>;
  }

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'User Actions by Hour',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Time of Day',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Actions',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default UserActionsByHour;
