import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { DEvent, DEventDTO } from '../types/DEvent';
import { SuccessApiResponse } from '../types/Api';
import { fetchDEvents, fetchAllOrgs, saveDEventsToDB, deleteEventFromDB } from '../services/dEventsService';
import { setLoading } from './appSlice';


interface dEventsState {
  allEvents: DEvent[];
  orgs: string[];
}

const initialState: dEventsState = {
  allEvents: [],
  orgs: []
};

export const loadDEvents = createAsyncThunk<
  SuccessApiResponse<DEvent[]>
>('dEvents/loadDEvents', async (_, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchDEvents();

    if (!('data' in response)) {
      throw new Error(response.message);
    }

    if (response.data) {
      return response;
    } else {
      throw new Error('Error retrieving data');
    }
  } finally {
    dispatch(setLoading(false));
  }
});

export const deleteEvent = createAsyncThunk<
  SuccessApiResponse<string>,
  { eventId: string }
>("dEvents/deleteEvent", async ({ eventId }) => {
  const response = await deleteEventFromDB({ eventId });
  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('error retreiving data');
  }
});

export const loadOrgs = createAsyncThunk<
  SuccessApiResponse<string[]>
>("dEvents/loadOrgs", async () => {
  const response = await fetchAllOrgs();

  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('error retreiving data');
  }
});

export const saveDEvents = createAsyncThunk<
  SuccessApiResponse<DEvent[]>,
  { events: DEventDTO[] }
>("dEvents/saveDEvents", async ({ events }) => {
  const response = await saveDEventsToDB({ events });

  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('error retreiving data');
  }
});


const dEventsSlice = createSlice({
  name: 'dEvents',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadDEvents.fulfilled, (state, action) => {
      state.allEvents = action.payload.data;
    });
    builder.addCase(saveDEvents.fulfilled, (state, action) => {
      state.allEvents = [...state.allEvents, ...action.payload.data];
    });
    builder.addCase(loadOrgs.fulfilled, (state, action) => {
      state.orgs = action.payload.data;
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      const deletedEventId = action.payload.data;
      state.allEvents = state.allEvents.filter(e => e._id !== deletedEventId)
    })
  }
});

export const selectOrgs = (state: RootState) => state.dEvents.orgs;
export const selectEvents = (state: RootState) => state.dEvents.allEvents;
export default dEventsSlice.reducer;
