import { minimumDate } from "../constants";
import { Day } from "../types/Day";
import { RawDateString } from "../types/utilTypes";

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getColorFromString = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return '#' + '00000'.substring(0, 6 - color.length) + color;
};

export const convertObjectToQueryString = (obj: { [x: string]: any }) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const filterByDateLangAndCat = (days: Day[], options: GraphOptions): Day[] => {
  return days.filter(day => {
    if (options?.parentCategories && !options.parentCategories.includes(day.category.parentCategory)) {
      return false
    }
    let langMatches = true;
    if (options?.lang) {
      langMatches = options?.lang.includes(day.lang);
    }
    const dayDate = new Date(day.dayAsDate);
    const startDate = options?.startPeriod ? new Date(options.startPeriod) : null;
    const endDate = options?.endPeriod ? new Date(options.endPeriod) : null;
    const startMatches = startDate ? dayDate >= startDate : true;
    const endMatches = endDate ? dayDate <= endDate : true;

    return langMatches && startMatches && endMatches;
  });
};

export const getColorFromRI = (RI: number) => RI <= 30 ? 'red' : RI <= 50 ? 'orange' : 'green';

export type Interval = 'weekString' | 'dayString';

export interface GraphOptions {
  interval?: Interval;
  lang?: ('HE' | 'AR')[];
  startPeriod?: string;
  endPeriod?: string;
  parentCategories?: string[];
}

export const getStartOfWeekFromDay = (day: RawDateString) => {
  const dayDate = new Date(day)
  const dayOfWeek = dayDate.getDay();
  const diff = dayDate.getDate() - dayOfWeek;

  const startOfWeek = new Date(day);
  startOfWeek.setDate(diff);
  return startOfWeek;
}


export interface SubmitError {
  disabled: boolean;
  reason: string;
}

export const isSubmitDisabled = (
  startPeriod: string,
  endPeriod: string
): SubmitError => {
  const startDate = new Date(startPeriod);
  const endDate = new Date(endPeriod);
  const today = new Date();

  if (startDate > endDate) {
    return { disabled: true, reason: 'Start date cannot be after end date.' };
  }

  if (endDate < startDate) {
    return { disabled: true, reason: 'End date cannot be before start date.' };
  }

  if (startDate > today) {
    return { disabled: true, reason: 'Start date cannot be in the future.' };
  }

  if (endDate > today) {
    return { disabled: true, reason: 'End date cannot be in the future.' };
  }

  if (startDate < new Date(minimumDate)) {
    return {
      disabled: true,
      reason: `Start date cannot be before ${new Date(
        minimumDate
      ).toDateString()}.`,
    };
  }

  return { disabled: false, reason: '' };
};

