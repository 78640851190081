import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { SuccessApiResponse } from '../types/Api';
import { setLoading } from './appSlice';
import { fetchAllUEvents } from '../services/analyticsService';
import { UEvent } from '../types/UEvent';


interface UEventsState {
  allUEvents: UEvent[];
}

const initialState: UEventsState = {
  allUEvents: [],
};

export const loadUEvents = createAsyncThunk<
  SuccessApiResponse<UEvent[]>
>('uEvents/loadUEvents', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await fetchAllUEvents();

    if (!('data' in response)) {
      throw new Error(response.message);
    }

    if (response.data) {
      return response;
    } else {
      throw new Error('Error retrieving data');
    }
  } finally {
    dispatch(setLoading(false));
  }
});


const dEventsSlice = createSlice({
  name: 'uEvents',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadUEvents.fulfilled, (state, action) => {
      state.allUEvents = action.payload.data;
    });
  }
});

export const selectUEvents = (state: RootState) => state.uEvents.allUEvents;
export default dEventsSlice.reducer;
