import auth from '../config/firebase';

export const baseURL =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';

export const getUserToken = async () => {
  const user = auth.currentUser;
  const token = user && (await user.getIdToken());
  return token;
};

export const createHeader = async () => {
  const token = await getUserToken();
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
};
