import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Category, Day } from '../types/Day';
import { SuccessApiResponse } from '../types/Api';
import { getAllDays, getCategories } from '../services/dataService';
import { Segments } from '../types/RipenessIndex';
import { setLoading } from './appSlice';

interface RIValues {
  total: number;
  HE: number;
  AR: number;
}

type SegmentsObject = {
  [key in Segments]: RIValues;
};


interface DataState {
  MHSData: Day[];
  WOData: Day[];
  categories: Category[];
  allData: Day[];
  RipenessIndices: SegmentsObject
}

const initialState: DataState = {
  WOData: [],
  MHSData: [],
  allData: [],
  categories: [],
  RipenessIndices: {
    [Segments.mhs]: {
      HE: 100,
      AR: 5
      , total: 110
    },
    [Segments.wo]: {
      HE: 2,
      AR: 10,
      total: 20
    },
    [Segments.confident]: {
      HE: 50,
      AR: 15,
      total: 30
    },
    [Segments.Willingness]: {
      HE: 10,
      AR: 20,
      total: 40
    },
    [Segments.firmness]: {
      HE: 104,
      AR: 25,
      total: 50
    },
    [Segments.hurt]: {
      HE: 15,
      AR: 30,
      total: 60
    },
    [Segments.impossibility]: {
      HE: 35,
      AR: 35,
      total: 70
    },
    [Segments.ri]: {
      HE: 80,
      AR: 10,
      total: 80
    },
  }
};


export const loadAllData = createAsyncThunk<
  SuccessApiResponse<Day[]>,
  string | undefined
>("data/loadAllData", async (queryString: string | undefined) => {
  const response = await getAllDays({ queryString });

  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('error retreiving categories');
  }
});

export const loadCategories = createAsyncThunk<
  SuccessApiResponse<Category[]>
>("data/loadCategories", async (_, { dispatch }) => {
  dispatch(setLoading(true))
  try {

    const response = await getCategories({});

    if (!('data' in response)) {
      throw new Error(response.message)
    }
    if (response.data) {
      return response;
    } else {
      throw new Error('error retreiving categories');
    }
  } finally {
    dispatch(setLoading(false))
  }
});

export const loadWOData = createAsyncThunk<
  SuccessApiResponse<Day[]>
>("data/loadWOData", async () => {
  const response = await getAllDays({ queryString: 'parentCategory=WO' });

  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('error retreiving data');
  }
});

export const loadMHSData = createAsyncThunk<
  SuccessApiResponse<Day[]>
>("data/loadMHSData", async () => {
  const response = await getAllDays({ queryString: 'parentCategory=MHS' });

  if (!('data' in response)) {
    throw new Error(response.message)
  }
  if (response.data) {
    return response;
  } else {
    throw new Error('error retreiving data');
  }
});

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setMHSData: (state, action: PayloadAction<Day[]>) => {
      state.MHSData = action.payload;
    },
    setWOData: (state, action: PayloadAction<Day[]>) => {
      state.WOData = action.payload;
    },
    setAllData: (state, action: PayloadAction<Day[]>) => {
      state.allData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMHSData.fulfilled, (state, action) => {
        state.MHSData = action.payload.data;
      })
      .addCase(loadWOData.fulfilled, (state, action) => {
        state.WOData = action.payload.data;
      })
      .addCase(loadCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data;
      })
      .addCase(loadAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data;
      })
  }
});

export const selectRipenessIndices = (state: RootState) => state.data.RipenessIndices;
export const selectAllData = (state: RootState) => state.data.allData;
export const selectMHSData = (state: RootState) => state.data.MHSData;
export const selectWOData = (state: RootState) => state.data.WOData;
export const selectCategories = (state: RootState) => state.data.categories;
export const { setMHSData, setWOData, setAllData } = dataSlice.actions;

export default dataSlice.reducer;
