import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useAppSelector } from '../../utils/hooks';
import { selectUEvents } from '../../store/uEventsSlice';

const UserActions = () => {
  const [chartData, setChartData] = useState<any>(null);
  const userEvents = useAppSelector(selectUEvents);

  useEffect(() => {
    const dateMap: {
      [key: string]: {
        [key in 'graphDownload' | 'RIDownload' | 'filter']: number;
      };
    } = {};

    userEvents.forEach((event) => {
      const { dayString, eventName } = event;
      if (!dateMap[dayString]) {
        dateMap[dayString] = {
          graphDownload: 0,
          RIDownload: 0,
          filter: 0,
        };
      }
      dateMap[dayString][eventName]++;
    });

    const labels = Object.keys(dateMap).sort();

    const graphDownloadData = labels.map(
      (label) => dateMap[label].graphDownload
    );
    const RIDownloadData = labels.map((label) => dateMap[label].RIDownload);
    const filterData = labels.map((label) => dateMap[label].filter);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Graph Download',
          data: graphDownloadData,
          fill: false,
        },
        {
          label: 'RI Download',
          data: RIDownloadData,
          fill: false,
        },
        {
          label: 'Filter',
          fill: false,
          data: filterData,
        },
      ],
    });
  }, [userEvents]);

  // Conditional rendering based on chartData being available
  if (!chartData) {
    return <div>Loading chart...</div>;
  }

  return (
    <div>
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Total User Actions Over Time',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Actions',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default UserActions;
