import { createHeader, baseURL } from './utils';
import { User } from '../types/User';
import { ApiServiceReturnType } from '../types/Api';

export const fetchUser = async (): Promise<ApiServiceReturnType<User>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/user`, { headers });
    const JSONResponse = await res.json();
    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};

export const getUsers = async (): Promise<ApiServiceReturnType<User[]>> => {
  const headers = await createHeader();

  try {
    const res = await fetch(`${baseURL}/users`, { headers });
    const JSONResponse = await res.json();
    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    console.error(e);
    return e;
  }
};


export const saveUserToDB = async (args: { organization: string }) => {
  const headers = await createHeader();
  try {
    const res = await fetch(`${baseURL}/getOrRegisterUser`, {
      headers,
      body: JSON.stringify(args),
      method: 'POST'
    });
    const JSONResponse = await res.json();
    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    console.error(e);
    return e;
  }
};
