import { selectRipenessIndices } from '../../../store/dataSlice';
import { Segments } from '../../../types/RipenessIndex';
import { getColorFromRI } from '../../../utils/functions';
import { useAppSelector } from '../../../utils/hooks';
import RIText from './RIText';

interface TrafficLightBlockProps {
  segment: Segments;
  x: number;
  y: number;
}

const TrafficLightBlock = ({ segment, x, y }: TrafficLightBlockProps) => {
  const RIs = useAppSelector(selectRipenessIndices);

  return (
    <g>
      <circle
        stroke='white'
        strokeWidth={1}
        cx={x - 20}
        cy={y}
        r={6}
        fill={getColorFromRI(RIs[segment].AR)}
      />
      <RIText x={x} y={y + 10} text={'AR'} />
      <circle
        stroke='white'
        strokeWidth={1}
        cx={x - 20}
        cy={y - 20}
        r={6}
        fill={getColorFromRI(RIs[segment].HE)}
      />
      <RIText x={x} y={y - 10} text={'HE'} />
    </g>
  );
};

export default TrafficLightBlock;
